<template>
  <div id="container">
    <div id="output">
      <div class="containerT">
        <h1>用户登录</h1>
        <form class="form" id="entry_form">
          <input type="text" placeholder="用户名" v-model="username">
          <input type="password" placeholder="密码" v-model="password">
          <button type="button" @click="submit()" :disabled="btnDisabled">登录</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api/index';
export default {
  name: "Login",
  data: function(){
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    btnDisabled: function(){
      return this.username === '' || this.password === '';
    }
  },
  methods: {
    submit: function(){
      this.$http.get(api.login, {params: {name: this.username, pwd: this.password}}).then(response => {
        if(response.data.success) {
          // sessionStorage.setItem('login', '1');
          // this.$router.replace('/admin')
          localStorage.setItem('token', response.data.res)
          localStorage.setItem('nick', response.data.nick)
          this.$router.replace('/')
        } else {
          this.$Message.error(response.data.err)
        }
      })
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #515a6e
}
.containerT {
  width: 400px;
  height: 300px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -200px;
  border-radius: 3px
}

.containerT h1 {
  font-size: 18px;
  font-family: microsoft yahei, Arial, sans-serif;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  /*-webkit-transition-timing-function: ease-in-put;*/
  /*transition-timing-function: ease-in-put;*/
  font-weight: 500
}

form {
  padding: 20px 0;
  position: relative;
  z-index: 2
}

form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, .4);
  background-color: rgba(255, 255, 255, .2);
  width: 200px;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 0 auto 10px;
  display: block;
  text-align: center;
  font-size: 15px;
  color: #000;
  -webkit-transition-duration: .25s;
  transition-duration: .25s;
  font-weight: 300
}

form input:hover {
  background-color: rgba(255, 255, 255, .4)
}

form input:focus {
  background-color: #fff;
  color: #333
}

form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background-color: #fff;
  border: 0;
  padding: 10px 15px;
  color: #333;
  border-radius: 3px;
  width: 200px;
  cursor: pointer;
  font-family: microsoft yahei, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition-duration: .25s;
  transition-duration: .25s
}

form button:hover {
  background-color: #f5f7f9
}
</style>